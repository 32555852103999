
import { IFilterValue } from '../../../../utils/constants/interfaces'
import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { AiFillCaretDown } from 'react-icons/ai'
import styled from 'styled-components'
import { Button, CheckboxInput } from '..'
import { Container, Dropdown, Text } from '../..'
import StyledDropdownInput from './dropdown-input-styled'
import DropdownActions from './dropdown-actions'
import Checkbox from '../../ui-elements/checkbox'
import { IoClose } from 'react-icons/io5'
import CalendarIcon from './calendar-icon'
import { getColor, sliceLabel } from 'utils'
import RangeDropdownInput from '../RangeDropdownInput'
import { hooks, mainSelectors, dataTrackingOperations } from 'state'
import SelectionSection from './selection-section'
import { eventsCategory, filterSelection } from 'utils/constants/events'
import Options from './Options'
import { DropdownInputPropsv2 } from './interfaces'

const Arrow = styled(Container)`

`


export const DropdownInputv2 = (props : DropdownInputPropsv2)=>{
  
  let { onChange: onChangeProps } = props
  const [searchedText, setSearchedText] = useState('')
  const [isShowAllSelected, setIsShowAllSelected] = useState(false)

  const initialValues = useMemo(() => {
    let sv = props.selectedValues
    return sv
  }, [props.selectedValues])
  const [values, setValues] = useState(props.selectedValues)
  const isShowOnlySelectionEnabled = useMemo(() => {
    if (searchedText?.length! > 0) {
      return false
    } else {
      return true
    }
  }, [searchedText])
  const [isExpanded, setIsExpanded] = useState(false)
  const [sortBy, setSortBy] = useState(props.sortBy)

  const options = useMemo(()=>{
    return props.options
  },[props.options])   

  useEffect(()=>{
    if(!isExpanded){
      setValues(props.selectedValues)
    }
  },[isExpanded])

  useEffect(()=>{
    setValues(props.selectedValues)
  },[props.selectedValues])

  const [filteredOptions, setFilteredOptions] = useState(props.options)
  const [message,  setMessage ] = useState('')
  const { selectmain } = mainSelectors
  const { trackEvent } = dataTrackingOperations
  const { useAppSelector, useAppDispatch } = hooks
  const { selectedWorkbookV2 } = useAppSelector(selectmain)
  const dispatch = useAppDispatch()
  const onClose = () => {
    setIsExpanded(false)
  }
  const handleOnChangeOption = (e:ChangeEvent<HTMLInputElement>, option: Record<string,any>) => {
    setValues((values: IFilterValue[])=>{
      const valuesNotSelected = values.filter((value:IFilterValue)=>value.id !== option.id)
      return [...valuesNotSelected, {value:option.label, id: option.id, isSelected:e.target.checked}] 
    })
  }

  const onChange = (item: any) => {
    if (item && item.label) {
      onChangeProps(item, sortBy)
      onClose()
      return
    }
    onClose()
  }


  const handleOnSearchText = (searchedText:string) => {
    searchedText ? 
      setIsShowAllSelected(true)
    :
      setIsShowAllSelected(false)
    setSearchedText(searchedText)
  }
  const onUpdateStates = (valuesN: any) => {
    let newValues = ''
    Object.keys(valuesN).forEach((value: string) => { if (valuesN[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const onUpdateCategories = (option: Record<string, any>) => {
    dispatch(trackEvent({ eventCategory: eventsCategory.filter_selection, values: { workbookGuid: selectedWorkbookV2?.workbookGuid, filterName: props.filterName, filterValues: values.map((v: IFilterValue) => ({ id: v.id, value: v.value })) }, event: filterSelection.pistil_filter_select }))
    let nVals = values.filter((v: IFilterValue) => v.isSelected)
    onChangeProps(nVals, sortBy)
    onClose()
    setIsShowAllSelected(false)
    setSearchedText('')
  } 

  const renderLabelsOfSelectedValues = () => {
    const selectedValuesLength = props.selectedValues.length
    const joinedValues = props.selectedValues.map((sv) => sv.value)
    return typeof props.label === 'boolean' ? <Text>{props.options[0].label}</Text>
      : <Text color={selectedValuesLength > 0 ? 'text' : 'gray1'}>{`${selectedValuesLength! > 1 ? `(${selectedValuesLength}) ` : ''}${selectedValuesLength !== 0 ? joinedValues : 'Select values'}`}</Text>
  }

  const filterSearchedValueInList = (value: string) => {
    if (value.length > 0) {
      const filteredValues = options.filter(({ label }: { label: string, id: string }) => label.toLowerCase().includes(value.toLowerCase()))
      filteredValues?.length > 0 ? setFilteredOptions(filteredValues) : setFilteredOptions((prev: any[]) => [])
    } else {
      return setFilteredOptions(options)
    }
  }


  const selectAllCheckboxes = (selection: boolean) => {
    const checkboxesSelected = filteredOptions.map((fo:any)=>({...fo, isSelected: selection, value: fo.label}))
    setValues((prev)=>[...checkboxesSelected, ...prev])
  }
  const handleChangeIndividualDropdownSelection = (e: React.ChangeEvent<HTMLInputElement>, onCheckOption: Function, option: Record<string, any>) => {
    dispatch(trackEvent({ eventCategory: eventsCategory.filter_selection, values: { filterName: props.filterName, filterValues: option.label }, event: filterSelection.pistil_filter_select }))
    onCheckOption(e);
    onChangeProps([{ id: option.id, value: option.label }], sortBy)
  }
  const renderTrigger = () => {
    if (props.trigger) {
      return (
        <Container className={'trigger'} onClick={() => (!props.onHover && isExpanded) ? onChange(props.selectedOption) : setIsExpanded(true)}>
          {props.trigger}
        </Container>
      )
    }

    const onClickHandler = () => {
      if (!props.onHover && isExpanded) {
        onChange(props.selectedOption)
        setFilteredOptions(options)
        setSortBy(props.sortBy)
      } else {
        setIsExpanded(true)
        setFilteredOptions(options)
        setSortBy(props.sortBy)
      }
    }

    return (
      <Container expand className={`trigger-container${props.isRangeDropdownInput ? ' large' : ' '}`} onClick={onClickHandler}>
        {
          props.isRangeDropdownInput && (
            <Container className={'label'} >
              <div style={{ marginRight: '10px' }}>
                <CalendarIcon styles={{ marginRight: '10px' }} color={getColor('brand')()} width='20' />
              </div>
            </Container>
          )
        }
        {
          (
            <Container className={'label'}>
              {
                renderLabelsOfSelectedValues()
              }
            </Container>
          )
        }
        {props.selectedOption?.label && <Text>{props.selectedOption.label}</Text>}
        {props.selectedOption?.icon}
        {
          props.isRangeDropdownInput && (
            <button style={{ border: 'none', cursor: 'pointer' }} className={`x-icon ${true ? 'visible' : 'hidden'}`} onClick={() => { }}>
              <IoClose />
            </button>
          )
        }
        <Container>
          {props.allowMultipleSelection && props.selectedValues.length > 0 && !props.isValueRequired && props.showClearButton && (
            <Button className={'clear'} tooltip={{ label: 'Clear selection', position: 'bottom', type: 'sigma' }} onClick={(e) => { e.stopPropagation(); props?.restoreSelectedValues!(); setValues([]) }}>
              <svg fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="css-9vk7ga"><title></title><path fill-rule="evenodd" clip-rule="evenodd" d="M11.293 12L7.646 8.354l.708-.708L12 11.293l3.646-3.647.708.708L12.707 12l3.647 3.646-.707.708L12 12.707l-3.646 3.647-.708-.707L11.293 12z" fill="currentColor"></path></svg>
            </Button>
          )}
          {!props.hideCaret && <Arrow className={`arrow`} ><AiFillCaretDown size={10} color='#736a8c' /></Arrow>}
        </Container>
      </Container>
    )
  }
  
  useEffect(() => {
    let hasValues = false
    Object.keys(values || {}).forEach((val: any) => {
      if (values[val]) {
        hasValues = true
      }
    })

    if (hasValues) {
      setMessage('')
    } else {
      setMessage(props.noSelectionMessage!)

    }
  }, [values])

  if (props.isRangeDropdownInput) {
    return (
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : ''} ${props.disabled ? 'disabled ' : ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <RangeDropdownInput staticRanges={props.staticRanges} />
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  if (!props.allowMultipleSelection) {
    return (
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : ''} ${props.disabled ? 'disabled ' : ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <DropdownActions filterSearchedValueInList={filterSearchedValueInList!} resetFilteredValues={() => { setFilteredOptions(options); setSearchedText('')}} setSearchedText={setSearchedText!} searchedText={searchedText!} options={filteredOptions} handleOptions={(options) => { setFilteredOptions(options) }} sortBy={sortBy} setSortBy={setSortBy} />
          <Container className={'options'}>
            {
              filteredOptions.length !== 0 ? filteredOptions.map((option: Record<string, any>) => {
                const onCheckOption = (e: any) => {
                  setValues((values: IFilterValue[]) => {
                    return values.map((value: IFilterValue) => {
                      if (value.id === option.id) {
                        return { ...value, isSelected: e.target.checked }
                      }
                      return value
                    })
                  })
                }
                const isChecked = props.selectedValues.find((sv) => sv.id === option.id)?.value ? true : false
                return (<Container key={option.id} className={`role-container${isChecked ? ' checked' : ' not-checked'}`} >
                  <Checkbox type='radio' checked={isChecked} onChange={(e) => { handleChangeIndividualDropdownSelection(e, onCheckOption, option) }} isRoundedCheckbox={true} label={option.label} name={props.filterSettingGuid} parentId={props.filterSettingGuid} id={option.id} />
                </Container>)
              }) : <div style={{ margin: '8px' }}><Text textStyle='s1' textAlign='left'>0 results found</Text></div>
            }
          </Container>
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  if (props.allowMultipleSelection) {
    return (
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : ''} ${props.disabled ? 'disabled ' : ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <DropdownActions filterSearchedValueInList={filterSearchedValueInList!} resetFilteredValues={() => { setFilteredOptions(options); setSearchedText('');}} setSearchedText={(value: string)=>handleOnSearchText(value)} searchedText={searchedText!} options={filteredOptions} handleOptions={(options) => { setFilteredOptions(options) }} sortBy={sortBy} setSortBy={setSortBy} />
          <Options options={filteredOptions} selectedValues={values} parentId={props.filterSettingGuid!} handleOnChangeOption={handleOnChangeOption}/>
          <Container className={'options'}>
            {
              message
                ? (
                  <Container className={'message'}>
                    <Text>{message}</Text>
                  </Container>
                )
                : (
                  filteredOptions.length !== 0 &&
                  <Button className={'action-button'} disabled={(!values?.find((v: IFilterValue) => { return v.isSelected })) && props.isValueRequired} onClick={onUpdateCategories}>
                    <Text>{'Apply'}</Text>
                  </Button>
                )
            } 
          </Container>
          {isShowOnlySelectionEnabled && <Container className={'bottom-option selected-option-label'}>
            {
              <SelectionSection values={values} handleOptions={(options) => { setFilteredOptions(options) }} options={options} sortBy={sortBy!} />
            }
          </Container>}
          {isShowAllSelected && props.filterName === 'Stores'  && <Container className={'bottom-option select-all'}>
                <CheckboxInput onChange={selectAllCheckboxes}>
                  <Text textStyle='p'>Select All</Text>
                </CheckboxInput>
              </Container>  
            } 
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  return (
    <div
      className={`dropdown-input-wrapper`}
      style={{
        display: 'flex',
        width: props.width || '100%',
        height: '100%',
      }}
      onMouseLeave={() => {
        if (props.onHover) {
          props.onHover()
          return setIsExpanded(false)
        }
      }}
      onMouseEnter={() => {
        if (props.onHover) {
          props.onHover()
          return setIsExpanded(true)
        }
      }}
    >
      <StyledDropdownInput className={`${props.clearStyles ? '' : 'default '}${isExpanded ? 'active ' : ''}${props.disabled ? 'disabled ' : ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          {props.isDropdownActionsActive && <DropdownActions filterSearchedValueInList={filterSearchedValueInList!} resetFilteredValues={props.resetFilteredValues!} searchedText={searchedText!} setSearchedText={setSearchedText!} options={options} handleOptions={() => { }} />}
          <Container className={'options'}>
            {filteredOptions.map((option: Record<string, any>) =>
              <Container key={option.id} className={`role`} onClick={() => onChange(option)}>
                <Text>{option.label}</Text>
              </Container>
            )}
          </Container>
        </Dropdown>
      </StyledDropdownInput>
    </div>
  )
}