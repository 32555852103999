import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'state';
import axios from 'axios';
import { UAParser } from 'ua-parser-js';
import { isMobile } from "react-device-detect"
import { isInsights } from 'utils/constants';
import { getUserFromSubcookies } from 'utils/tools';

const getEnvironment = () => {
  if (process.env["REACT_APP_ENVIRONMENT"] === 'prod-pfb'
    || process.env["REACT_APP_ENVIRONMENT"] === 'prod-pfr') {
    return 'prod'
  }
  return process.env["REACT_APP_ENVIRONMENT"]
}

// There is no other active apps so far beside Insights
const getApplication = () => isInsights ? 'pfi2' : 'pfi';

const environment = getEnvironment()
const application = getApplication()
const trackingInit = createAsyncThunk('data-tracking/trackingInit', async (props: void, { rejectWithValue, dispatch }) => {
  const userAgentParser = new UAParser(navigator.userAgent);
  const { dataTrackingSessionId: sessionId } = store.getState().auth
  const { profile: { sub: userGuid } } = getUserFromSubcookies()
  try {
    const queryParams = {
      sessionId,
      userGuid,
      application,
      deviceType: isMobile ? 'mobile' : 'desktop',
      device: navigator.userAgent,
      operativeSystem: [userAgentParser.getOS().name, userAgentParser.getOS().version].filter(Boolean).join(" "),
      browser: userAgentParser.getBrowser().name,
      creationDatetimeUtc: (new Date()).toISOString(),
      environment
    }
    await axios.post(`${process.env.REACT_APP_DATA_TRACKING_API_URL}/Metadata_Post`,
      queryParams,
      {
        headers: {
          'x-functions-key': `${process.env.REACT_APP_DATA_TRACKING_API_KEY}`
        }
      }
    );
    return ({ sessionId, application, environment })
  } catch (err) {
    if (!(err as Record<string, string>).response) {
      throw err
    }
    return rejectWithValue(err)
  }
  // try {
  //     return ({ sessionId, application, environment })
  //   } catch (err) {
  //     if (!(err as Record<string, string>).response) {
  //       throw err
  //     }
  //     return rejectWithValue(err)
  //   }    
}
)

const trackEvent = createAsyncThunk('data-tracking/trackEvent', async (props: any, { rejectWithValue, dispatch }) => {
  const { eventCategory, event, values, userGuid: userGuid_ } = props;
  const dateTimeUtc = (new Date()).toISOString()
  const { dataTrackingSessionId } = store.getState().auth
  try {
    if (!dataTrackingSessionId) {
      throw 'noSessionId'
    }
    const { profile: { sub: userGuid } } = getUserFromSubcookies()
    const params = {
      dateTimeUtc,
      eventCategory,
      event,
      values: typeof values === 'object' ? JSON.stringify(values) : values,
      sessionId: dataTrackingSessionId,
      application,
      environment,
      userGuid: userGuid_ || userGuid
    }
    await axios.post(`${process.env.REACT_APP_DATA_TRACKING_API_URL}/Event_Tracking_Post`,
      params,
      {
        headers: {
          'x-functions-key': `${process.env.REACT_APP_DATA_TRACKING_API_KEY}`
        }
      }
    );
  } catch (err) {
    if (!(err as Record<string, string>).response) {
      throw err
    }
    return rejectWithValue(err)
  }
}
)

const dataTrackingActions = {
  trackingInit,
  trackEvent,
}

export default dataTrackingActions
