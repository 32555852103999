import styled from "styled-components"
import { Container } from "../containers"
import { BiCheck } from "react-icons/bi"
import { Text } from "."
import { getColor, sliceLabel } from "../../../utils"

interface ICheckboxProps {
    isRoundedCheckbox?: boolean
    className?: string
    type: string
    checked: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement>
    label: string
    name?: string
    parentId?: string
    id?: string
}

const StyledCheckbox = styled(Container)`
  width: 100%;
  label{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .squared-checkbox-container{
      border-radius: 2px;
      border: 1px solid ${getColor('sigmaBorder')};
      width: 14px;
      height: 14px;
      &:hover{
        cursor: pointer;
        border: 1px solid ${getColor('brand')};
      }
      .check-container{
        display: none;
        color: ${getColor('brand')};
      }
    }
    .rounded-checkbox-container{
      border-radius: 50%;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      border: 1px solid ${getColor('brand')};
      background-color: #fbfaff;
      padding: 3px;
      cursor: pointer;
      .check-container{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        &.checked-container{
          background-color: ${getColor('brand')};
        }
        &.not-checked-container{
          background-color: none;
          display: none;
        }
        .rounded-check{
          height: 100%;
          width: 100%;
          border-radius: 50%;
          &.checked{
            background-color: ${getColor('brand')};
          }
          &.not-checked{
            background-color: white;
          }
        }

      }
    }
    input{
      display: none;
      &:checked + .squared-checkbox-container{
        .check-container{
          display: flex;
        }
      }
    }
  }
`
export default function Checkbox({type, checked, onChange, label, isRoundedCheckbox = false,name, parentId='', id=''}:ICheckboxProps) {
  return (
    <StyledCheckbox className={'checkbox'}>
      <label htmlFor={`${parentId}${id}`}>
        <div className="checkbox-container">
          <input id={`${parentId}${id}`} checked={checked} type={type} onChange={onChange} name={name} key={id}/>
          <Container className={`${isRoundedCheckbox ?  'rounded':'squared'}-checkbox`}>
            <Container className={`check-container ${checked ? 'checked' : 'not-checked'}`}>
              {
                isRoundedCheckbox ? 
                <div className={`rounded-check ${checked ? 'checked' : 'not-checked'}`}></div>
                : <BiCheck/>
              }
            </Container>
          </Container>
        </div>
        <Text>{label}</Text>
      </label>
    </StyledCheckbox>
  )
}
